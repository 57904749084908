body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout{
  min-height: 100vh;
}

.header_space_brand{
  font-size: 20px;
  font-weight: bold;
}

.ant-layout-header {
  padding-inline: 20px !important;
}

.sub_header{
  background-color: #cfe9ff !important;
  height:55px !important;
  line-height: 50px !important;
}

.sub_header > .ant-row{
  flex-flow: column;
}

.sub_header > .ant-row > .ant-space{
  justify-content: space-between;
}

.ant-layout-footer {
  color: rgb(23 119 255) !important;
  background: #001628 !important;
}

.ant-list-item-meta{
  align-items: center !important;
  margin-right: 5px !important;
}

.ant-list-item-meta-title{
  margin-top: 0;
}

.ant-list-item {
  padding: 10px 0 !important;
}

.btnSuccess{
  background-color: #52c41a !important;
}

.btnSuccess:hover{
  background-color: #95de64 !important;
}

.btnSuccess:active{
  background-color: #b7eb8f !important;
}

.btnPurple{
  background-color: #9254de !important;
}

.btnPurple:hover{
  background-color: #b37feb !important;
}

.btnPurple:active{
  background-color: #d3adf7 !important;
}
